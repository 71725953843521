import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const YearPage = ({ pageContext: { previous, next, period } }) => (
  <Layout>
    <Seo title={`${period.year}`} />
      <div className="container">
			<div className="row-fluid">
				<h1 className="text-center">
					{previous && (
            <small><a href={`/year/${previous}`} className="text-muted">&laquo;{previous}</a></small>
          )}
					{ ` ${period.year} ` }
					{next && (
            <small><a href={`/year/${next}`} className="text-muted">{next} &raquo;</a></small>
          )}
				</h1>
				<h3>{ period.title }</h3>
				<div dangerouslySetInnerHTML={{ __html: period.html }} />
			</div>
		</div>
  </Layout>
)

export default YearPage
